import { createContext, useContext } from 'react';
import type { Country, Language } from '@seek/melways-sites';
import { Theme } from '@seek/cmsu-cms-connect';

type CMSUStateContext = {
  language: Language;
  country?: Country;
  theme?: Theme;
  utmParameters?: string;
};

const CMSUContext = createContext<CMSUStateContext | null>(null);

export const useCMSUContext = () => {
  const context = useContext(CMSUContext);

  if (context === null) {
    throw new Error('useCMSUContext must be used within a CMSUProvider');
  }

  return context;
};

type CMSUProviderProps = React.PropsWithChildren<{
  config: CMSUStateContext;
}>;

export const CMSUProvider = ({ children, config }: CMSUProviderProps) => (
  <CMSUContext.Provider value={{ theme: Theme.SeekJobs, ...config }}>
    {children}
  </CMSUContext.Provider>
);
