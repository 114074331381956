import { datalabHelper } from '../../datalabHelper';
import { useDatalab } from '../../DatalabProvider';
import type { PdfDataSummary } from './custom-template/summary';
import { useDownloadTalPdf } from './useDownloadTalPdf';

import { forwardRef, useMemo } from 'react';

const translateData = (
  data: PdfDataSummary[],
  dataTranslations: Map<string, string>,
) =>
  data.map((item) => ({
    ...item,
    driverName: datalabHelper.getTranslationFor(
      dataTranslations,
      item.driverName,
    ),
    category: item.category.map((category) => ({
      ...category,
      name: datalabHelper.getTranslationFor(dataTranslations, category.name),
      mustHave: datalabHelper
        .getTranslationFor(dataTranslations, category.mustHave)
        .toLowerCase(),
    })),
  }));

export const DownloadTalPdfButton = forwardRef<
  HTMLAnchorElement,
  { data: PdfDataSummary[] }
>(({ data }, ref) => {
  const { locale, dataTranslations, fullURL } = useDatalab();
  const translatedData = useMemo(
    () => translateData(data, dataTranslations),
    [data, dataTranslations],
  );

  const instance = useDownloadTalPdf(
    translatedData,
    locale ?? 'en-AU',
    fullURL,
  );
  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <>
      {instance.url ? (
        <a
          style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: '0',
          }}
          href={instance.url}
          target="_blank"
          rel="noreferrer"
          download="talent-attraction-lab-report.pdf"
          ref={ref}
        />
      ) : null}
    </>
  );
});

DownloadTalPdfButton.displayName = 'DownloadTalPdfButton';
