import { useTrackLink } from '@seek/cmsu-analytics';
import type { LinkFragment, TipsCardFragment } from '@seek/cmsu-cms-connect';
import type { Country, Language, Locale, SiteName } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  Divider,
  Hidden,
  Inline,
  Stack,
  Text,
  ToastProvider,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTargetBlank } from '../../../../../apps/unified-apac/src/client/shared/hooks/useTargetBlank';
import type { GetSurveyQuery } from '../../gql/tal/generated';
import translations_tal from './.vocab';
import { DatalabProvider, useDatalab } from './DatalabProvider';
import { CandidateInformation } from './components/CandidateInformation/CandidateInformation';
import { DriverBreakdown } from './components/DriverBreakdown/DriverBreakdown';
import { DriverSummary } from './components/DriverSummary/DriverSummary';
import { NoDataDisplay } from './components/NoDataDisplay/NoDataDisplay';
import {
  PdfDownloadProvider,
  usePdfDownloadContext,
} from './components/PDF/PdfDownloadProvider';
import type { DriverList, TrackLink } from './components/QueryFilter';
import { QueryFilterDrawer } from './components/QueryFilterDrawer';
import { QueryFilterForm } from './components/QueryFilterForm';
import { TipsCard } from './components/TipsCard/TipsCard';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabQueryParams,
  type DatalabSurvey,
} from './datalabHelper';
import { datalabMapper } from './datalabMapper';
import { tipCardWrapperDesktop, tipCardWrapperMobile } from './styles.css';
import type { AboutResearchLink } from './types';
import { toUrl } from '../../utils';

// TODO!: Replace with actual page title
const pageTitle = 'Talent Acquisition Lab';

interface DatalabProps {
  datalabSurvey: DatalabSurvey;
  tipsCards?: ({ __typename?: 'TipsCard' | undefined } & TipsCardFragment)[];
  aboutResearchLink:
    | ({
        __typename?: 'Link';
      } & LinkFragment)
    | null
    | undefined;
  fullURL: string;
  country: Country;
  locale: Locale;
  site: SiteName;
  language: Language;
}

export const Datalab = ({
  datalabSurvey: {
    queryFilters,
    drivers,
    surveyResults,
    queryFilterParams,
    translations,
    fullURLWithQueryParams,
  },
  tipsCards,
  fullURL,
  aboutResearchLink,
  country,
  locale,
  site,
  language,
}: DatalabProps) => {
  useTargetBlank();
  // Get the params from context.

  const driverList: DriverList = datalabHelper.getDriverListFrom(queryFilters);
  const [showFilterDrawer, setShowDrawerFilter] = useState<boolean>(false);
  const showNoData: boolean = datalabHelper.hasNoSurveyData(surveyResults);
  const { primary, secondary } = datalabHelper.getPrimaryAndSecondary(
    drivers?.driverOptions,
  );

  const trackLink = useTrackLink('datalab-elements', {
    drivers: datalabHelper.getAnalyticsData(driverList, primary, secondary),
  });

  const datalabTranslations =
    datalabMapper.mapToDataTranslationsMap(translations);

  const transformedAboutResearchLink: AboutResearchLink = {
    href: toUrl(aboutResearchLink?.to, language),
    openLinkInNewTab: aboutResearchLink?.openLinkInNewTab,
  };
  return (
    <DatalabProvider
      fullURL={fullURL}
      fullURLWithQueryParams={fullURLWithQueryParams}
      siteLocale={locale}
      datalabTranslations={datalabTranslations}
    >
      <ToastProvider>
        <PdfDownloadProvider drivers={drivers}>
          <Box
            paddingY={{ mobile: 'medium', desktop: 'none' }}
            paddingX={{ mobile: 'none', desktop: 'medium' }}
          >
            <Columns
              space={{ mobile: 'medium', desktop: 'xlarge' }}
              collapseBelow="desktop"
            >
              <Column width="content">
                <DatalabNavigation
                  country={country}
                  driverList={driverList}
                  queryFilterParams={queryFilterParams}
                  setShowDrawerFilter={setShowDrawerFilter}
                  showFilterDrawer={showFilterDrawer}
                />
                {tipsCards?.length && (
                  <Box className={tipCardWrapperDesktop}>
                    <TipsCard tipsCards={tipsCards} />
                  </Box>
                )}
              </Column>
              <VerticalDivider />
              <Column>
                <Box
                  paddingY={{ mobile: 'none', desktop: 'xxlarge' }}
                  paddingTop={{ mobile: 'xsmall', desktop: 'xlarge' }}
                  position={'relative'}
                >
                  {showNoData ? (
                    <NoDataDisplay site={site} />
                  ) : (
                    drivers &&
                    surveyResults && (
                      <Stack space="xlarge">
                        <DatalabResult
                          aboutResearchLink={transformedAboutResearchLink}
                          country={country}
                          fullURL={fullURLWithQueryParams}
                          queryFilterParams={queryFilterParams}
                          drivers={drivers}
                          surveyResults={surveyResults}
                          trackLink={trackLink}
                        />
                        <CandidateInformation
                          demographic={surveyResults?.demographic}
                        />
                      </Stack>
                    )
                  )}
                  {!showNoData && (
                    <Box paddingTop="xlarge">
                      <Stack space="medium">
                        <Divider />
                        <Inline space="small">
                          <DownloadButton trackLink={trackLink} />
                        </Inline>
                      </Stack>
                    </Box>
                  )}
                  {tipsCards?.length && (
                    <Box className={tipCardWrapperMobile}>
                      <TipsCard tipsCards={tipsCards} />
                    </Box>
                  )}
                </Box>
              </Column>
            </Columns>
          </Box>
        </PdfDownloadProvider>
      </ToastProvider>
    </DatalabProvider>
  );
};

const DownloadButton = ({ trackLink }: { trackLink: TrackLink }) => {
  const { downloadPdf } = usePdfDownloadContext();
  const { t } = useTranslations(translations_tal);
  const onClick = () => {
    if (trackLink) {
      trackLink({
        elementDesign: 'button',
        elementText: 'Download PDF Bottom',
        elementAction: `download file`,
      });
      downloadPdf();
    }
  };
  return (
    <Button variant="solid" tone="formAccent" onClick={onClick}>
      {t('Download report')}
    </Button>
  );
};

interface DatalabNavigationProps {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
  setShowDrawerFilter: Dispatch<SetStateAction<boolean>>;
  country: Country;
  showFilterDrawer: boolean;
}

const DatalabNavigation = ({
  driverList,
  queryFilterParams,
  setShowDrawerFilter,
  country,
  showFilterDrawer,
}: DatalabNavigationProps) => (
  <Box paddingTop={{ mobile: 'none', desktop: 'xlarge' }}>
    <Hidden below="desktop">
      <Box display={'flex'} flexShrink={0} style={{ width: '351px' }}>
        <QueryFilterForm
          driverList={driverList}
          queryFilterParams={queryFilterParams}
        />
      </Box>
    </Hidden>
    <Hidden above="tablet">
      <QueryFilterDrawer
        country={country}
        driverList={driverList}
        queryFilterParams={queryFilterParams}
        setShowFilterDrawer={setShowDrawerFilter}
        showFilterDrawer={showFilterDrawer}
      />
    </Hidden>
  </Box>
);

interface DatalabResultProps {
  fullURL: string;
  queryFilterParams: DatalabQueryParams;
  drivers: DatalabDriverData;
  surveyResults: GetSurveyQuery;
  country: Country;
  trackLink: TrackLink;
  aboutResearchLink: AboutResearchLink;
}

const DatalabResult = ({
  fullURL,
  queryFilterParams,
  drivers,
  surveyResults,
  country,
  trackLink,
  aboutResearchLink,
}: DatalabResultProps) => {
  const { dataTranslations } = useDatalab();

  const translatedFilteredDriverOptions: string =
    datalabHelper.generateDriverSummaryTitleFromFilteredDriverOptionsInQueryParam(
      queryFilterParams,
      dataTranslations,
    );

  return (
    <Box>
      <Stack space="xlarge">
        <DriverSummary
          country={country}
          pageUrl={fullURL}
          pageTitle={pageTitle}
          filteredDriverOptions={translatedFilteredDriverOptions}
          totalRespondentCount={surveyResults.totalRespondents}
          respondentCount={surveyResults.respondents}
          trackLink={trackLink}
          aboutResearchLink={aboutResearchLink}
        />
        <Box>
          <DriverDescription />
        </Box>

        <DriverBreakdown drivers={drivers} trackLink={trackLink} />
      </Stack>
    </Box>
  );
};

const DriverDescription = () => {
  const { t: t_tal } = useTranslations(translations_tal);
  return (
    <Stack space="small">
      <Text size="large" weight="strong">
        {t_tal('Drivers that attract these candidates')}{' '}
      </Text>
      <Text>
        {t_tal(
          'These are the drivers candidates consider when looking at roles.',
        )}{' '}
        {t_tal('Drivers are ranked with the most important driver at the top.')}{' '}
        {t_tal('Expand each driver for more detail.')}
      </Text>
    </Stack>
  );
};

const VerticalDivider = () => (
  <Column width="content" hideBelow="desktop">
    <Box
      style={{
        height: '100%',
        width: '2px',
        backgroundColor: vars.backgroundColor.neutralLight,
      }}
    />
  </Column>
);
