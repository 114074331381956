import React from 'react';
import { Box, List, Text } from 'braid-design-system';
import type { RichTextContent } from '@graphcms/rich-text-types';
import {
  RichText,
  renderers,
  type NodeRendererType,
} from '@seek/cmsu-rich-text';
import type { ParagraphFragment } from '@seek/cmsu-cms-connect';

const handleAlignment = (
  align: ParagraphFragment['align'],
): 'center' | 'flexEnd' | undefined => {
  if (align === 'center') {
    return 'center';
  } else if (align === 'right') {
    return 'flexEnd';
  }

  return undefined;
};

type Props = Pick<ParagraphFragment, 'align' | 'tone' | 'size'> & {
  content: RichTextContent;
  marginBottom?: ParagraphFragment['marginBottom'];
};

export const Paragraph = ({
  content,
  align,
  tone,
  size,
  marginBottom,
}: Props) => {
  const paragraph: NodeRendererType['p'] = ({ children }) => (
    <Text
      component="p"
      tone={tone || undefined}
      align={align || undefined}
      size={size || undefined}
    >
      {children}
    </Text>
  );

  const listAlignment = handleAlignment(align);

  const list: (
    type: 'bullet' | 'number',
  ) => NodeRendererType['ul'] | NodeRendererType['ol'] =
    (type) =>
    ({ children }) =>
      (
        // Since list does not support alignment, we're doing it in the Box component
        // As a result, we need to modify align as center, flexEnd or undefined
        <Box display="flex" justifyContent={listAlignment}>
          <List space={'medium'} tone={tone || undefined} type={type}>
            {children}
          </List>
        </Box>
      );

  /**
   * Overriding the heading renderers so they render as paragraphs as well
   */
  const paragraphRenderers: NodeRendererType = {
    ...renderers,
    p: paragraph,
    h1: paragraph,
    h5: paragraph,
    h6: paragraph,
    ul: list('bullet'),
    ol: list('number'),
  };

  return (
    <Box marginBottom={marginBottom || 'none'}>
      <RichText renderers={paragraphRenderers} content={content} />
    </Box>
  );
};
