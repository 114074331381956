import { useTrackLink } from '@seek/cmsu-analytics';
import type { Article } from '@seek/cmsu-cms-connect';
import { Badge, Bleed, Box, Stack, Text } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { resizeImageFromHygraph } from '../../utils/imageHelper';

type Props = {
  article: Article;
  articleLink: string;
  articleCardFooterText: string;
  hideCategory?: boolean;
  index?: number;
};

export const ArticleCard = ({
  article,
  articleLink,
  articleCardFooterText,
  hideCategory = false,
  index = 0,
}: Props) => {
  const trackLink = useTrackLink('image-link', {
    elementText: `${article.title} - Position: ${index + 1}`,
    elementLink: articleLink,
    elementDesign: 'link',
  });

  const articleItemNav = () => {
    trackLink?.();
    // When the next page is cached, it will not be able to fire a new pageview event as the parent is unmounted instantly
    const timeoutId = setTimeout(() => {
      window.location.href = articleLink;
    }, 500);
    return () => clearTimeout(timeoutId);
  };
  return (
    <Box
      onClick={() => articleItemNav()}
      borderRadius="large"
      padding="gutter"
      overflow="hidden"
      height="full"
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      style={{
        border: `2px solid ${vars.borderColor.neutralLight}`,
        gap: vars.space.gutter,
        cursor: 'pointer',
      }}
      textAlign="left"
      background="surface"
      data={{ testid: 'CardTestId' }}
    >
      <Box data={{ testid: 'ArticleFeatureImageTestId' }}>
        <Bleed horizontal="gutter" top="gutter">
          <img
            src={resizeImageFromHygraph({
              imageUrl: article.featureImage[0]?.url,
              width: 480,
              height: 320,
            })}
            alt={article.featureImage[0]?.alt || undefined}
            style={{
              width: '100%',
              height: '160px',
              objectFit: 'cover',
              overflow: 'hidden',
              backgroundColor: vars.backgroundColor.neutralSoft,
            }}
          />
        </Bleed>
      </Box>
      <Box
        height="full"
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={'column'}
      >
        <Box paddingBottom="large">
          <Stack space="gutter">
            {!hideCategory && article.category && (
              <Badge tone="neutral" data={{ testid: 'ArticleCategoryTestId' }}>
                {article.category.name}
              </Badge>
            )}
            <Text
              weight="strong"
              size="large"
              data={{ testid: 'Article_Title_TestId' }}
            >
              {article.title}
            </Text>
          </Stack>
        </Box>
        <Text
          tone="secondary"
          size="small"
          data={{ testid: 'Article_Footer_TestId' }}
        >
          <div suppressHydrationWarning>{articleCardFooterText}</div>
        </Text>
      </Box>
    </Box>
  );
};
