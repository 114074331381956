import { Locale } from '../../generated/graphql';

export const filterObjectsByLocale = <T>(
  objectsWithLocale: { locale: Locale }[] | undefined,
  localeFilter: Locale,
): T =>
  objectsWithLocale
    ? (objectsWithLocale.filter((item) => item.locale === localeFilter) as T)
    : ([] as T);

export const filterObjectsByContentLocaleOrDefault = <T>(
  objectsWithLocale: { locale: Locale }[] | undefined,
  hygraphLocale: Locale,
): T => {
  if (objectsWithLocale && objectsWithLocale.length > 0) {
    const localizedItems = filterObjectsByLocale(
      objectsWithLocale,
      hygraphLocale,
    ) as T[];
    const defaultLocaleItems = filterObjectsByLocale(
      objectsWithLocale,
      Locale.Default,
    ) as T[];

    if (localizedItems.length === 0 && defaultLocaleItems.length === 0)
      return [] as T;

    return localizedItems.length > 0
      ? (localizedItems as T)
      : (defaultLocaleItems as T);
  }
  return objectsWithLocale as T;
};

export const appendDefaultLocale = (
  locales: Locale | Locale[],
): Locale | Locale[] => {
  if (locales.indexOf(Locale.Default) > -1) return locales;
  return Array.isArray(locales)
    ? locales.concat(Locale.Default)
    : [locales, Locale.Default];
};
