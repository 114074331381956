import { useTrackLink } from '@seek/cmsu-analytics';
import type {
  ButtonLinkFragment,
  CButtonLinkTone,
  CButtonVariant,
} from '@seek/cmsu-cms-connect';
import { ButtonLink as BraidButtonLink } from 'braid-design-system';
import { renderIcon } from '../IconBlockGroup/renderer';
import type { ButtonStyleProps } from '../types';

export const ButtonLink = ({
  link,
  text,
  variant,
  tone,
  openLinkInNewTab,
  size,
  icon,
  iconPosition,
  uniqueTrackingId,
  trackingAttributes,
}: {
  link: string;
  text: string;
  variant?: ButtonStyleProps['variant'] | CButtonVariant;
  tone?: ButtonStyleProps['tone'] | CButtonLinkTone;
  openLinkInNewTab?: boolean;
  size?: ButtonStyleProps['size'];
  uniqueTrackingId: string;
  trackingAttributes?: Record<string, string>;
} & Pick<ButtonLinkFragment, 'icon' | 'iconPosition'>) => {
  const onClickLink = useTrackLink(uniqueTrackingId, {
    ...trackingAttributes,
    elementDesign: 'button',
  });

  return (
    <BraidButtonLink
      href={link}
      target={openLinkInNewTab ? '_blank' : undefined}
      size={size || 'standard'}
      variant={variant || 'solid'}
      tone={tone || 'neutral'}
      onClick={() => (onClickLink ? onClickLink() : undefined)}
      role="link"
      icon={(icon && renderIcon(icon)) || undefined}
      iconPosition={iconPosition || undefined}
    >
      {text}
    </BraidButtonLink>
  );
};
