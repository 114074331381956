import type { Country } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonIcon,
  Heading,
  IconDownload,
  Inline,
  Spread,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
  TooltipRenderer,
} from 'braid-design-system';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import translations_tal from '../../.vocab';
import { datalabHelper } from '../../datalabHelper';
import { usePdfDownloadContext } from '../PDF/PdfDownloadProvider';
import { ShareDropdown } from '../ShareDropdown/ShareDropdown';
import type { TrackLink } from '../QueryFilter';
import { formatNumber } from '../../../../helpers/helper';
import { useDatalab } from '../../DatalabProvider';
import type { AboutResearchLink } from '../../types';

interface QuerySummaryProps {
  filteredDriverOptions: string;
  totalRespondentCount: number;
  respondentCount: number;
  pageUrl: string;
  pageTitle: string;
  country: Country;
  trackLink: TrackLink;
  aboutResearchLink: AboutResearchLink;
}

const DownloadButton = ({ trackLink }: { trackLink: TrackLink }) => {
  const { t } = useTranslations(translations_tal);
  const { downloadPdf } = usePdfDownloadContext();
  const onClick = () => {
    if (trackLink)
      trackLink({
        elementDesign: 'button',
        elementText: 'Download PDF Top',
        elementAction: `download file`,
      });
    downloadPdf();
  };

  return (
    <ButtonIcon
      size="standard"
      icon={<IconDownload />}
      id="download-pdf-button"
      label={t('Download PDF')}
      variant="soft"
      onClick={onClick}
    />
  );
};

const ShareButton = ({
  pageUrl,
  pageTitle,
  trackLink,
}: {
  pageUrl: string;
  pageTitle: string;
  trackLink: TrackLink;
}) => (
  <ShareDropdown pageUrl={pageUrl} title={pageTitle} trackLink={trackLink} />
);

const RespondentInfo = ({
  totalRespondentCount,
  respondentCount,
  country,
  aboutResearchLink,
}: {
  totalRespondentCount: number;
  respondentCount: number;
  country: Country;
  aboutResearchLink: AboutResearchLink;
}) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { locale } = useDatalab();

  const dateRange = t_tal(datalabHelper.getDateRange(country));
  return (
    <Text>
      {t_tal('Results are based on')}{' '}
      <TooltipRenderer
        id="respondent-tooltip"
        tooltip={
          <Text>
            {t_tal(
              'This sample size is statistically significant for the filters you have chosen.',
            )}
          </Text>
        }
      >
        {({ triggerProps }) => (
          <Box {...triggerProps} display="inline">
            <TextLinkButton>
              {formatNumber(respondentCount, locale)}
            </TextLinkButton>
          </Box>
        )}
      </TooltipRenderer>{' '}
      {t_tal('respondents from a total of')}{' '}
      <TextLink
        href={aboutResearchLink.href || '#'}
        target={aboutResearchLink.openLinkInNewTab ? '_blank' : '_self'}
      >
        {formatNumber(totalRespondentCount, locale)}{' '}
        {t_tal('candidates surveyed')}
      </TextLink>{' '}
      {dateRange}.
    </Text>
  );
};

const DriverSummaryTitle: React.FC<{ filteredDriverOptions: string }> = ({
  filteredDriverOptions,
}) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { title } = datalabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  return <Text>{t_tal(title)}</Text>;
};

const DriverSummaryAction: React.FC<{
  pageUrl: string;
  pageTitle: string;
  trackLink: TrackLink;
}> = ({ pageUrl, pageTitle, trackLink }) => (
  <Box position={'absolute'} right={0}>
    <Inline space={'small'}>
      <DownloadButton trackLink={trackLink} />
      <ShareButton
        pageUrl={pageUrl}
        pageTitle={pageTitle}
        trackLink={trackLink}
      />
    </Inline>
  </Box>
);

type DriverSummaryHeaderProps = {
  children: Parameters<typeof Spread>[0]['children'];
};

const DriverSummaryHeader: React.FC<DriverSummaryHeaderProps> = ({
  children,
}) => (
  <Spread space={'large'} alignY={'center'}>
    {children}
  </Spread>
);

const DriverSummaryResult: React.FC<{
  filteredDriverOptions: string;
  totalRespondentCount: number;
  respondentCount: number;
  country: Country;
  aboutResearchLink: AboutResearchLink;
}> = ({
  filteredDriverOptions,
  totalRespondentCount,
  respondentCount,
  country,
  aboutResearchLink,
}) => {
  const { hasDriverOptions } = datalabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  const { t: t_tal } = useTranslations(translations_tal);
  const { isMobile } = useScreenSize();
  return (
    <Stack space={'medium'}>
      {/* Add max width to avoid UI Block by the DriverSummary Action */}
      <Box style={{ maxWidth: isMobile ? '100%' : '90%' }}>
        <Heading level={'3'}>
          {hasDriverOptions ? filteredDriverOptions : t_tal('All respondents')}
        </Heading>
      </Box>

      <RespondentInfo
        aboutResearchLink={aboutResearchLink}
        country={country}
        totalRespondentCount={
          hasDriverOptions ? totalRespondentCount : respondentCount
        }
        respondentCount={respondentCount}
      />
    </Stack>
  );
};

type DriverSummaryContentProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContent: React.FC<DriverSummaryContentProps> = ({
  children,
}) => <Stack space={{ mobile: 'gutter', desktop: 'medium' }}>{children}</Stack>;

type DriverSummaryContainerProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContainer: React.FC<DriverSummaryContainerProps> = ({
  children,
}) => <Stack space={'large'}>{children}</Stack>;

const DriverSummary: React.FC<QuerySummaryProps> = ({
  filteredDriverOptions,
  totalRespondentCount,
  respondentCount,
  pageUrl,
  pageTitle,
  country,
  trackLink,
  aboutResearchLink,
}) => (
  <DriverSummaryContainer>
    <DriverSummaryHeader>
      <DriverSummaryTitle filteredDriverOptions={filteredDriverOptions} />
      <DriverSummaryAction
        pageUrl={pageUrl}
        pageTitle={pageTitle}
        trackLink={trackLink}
      />
    </DriverSummaryHeader>
    <DriverSummaryContent>
      <DriverSummaryResult
        aboutResearchLink={aboutResearchLink}
        country={country}
        filteredDriverOptions={filteredDriverOptions}
        totalRespondentCount={totalRespondentCount}
        respondentCount={respondentCount}
      />
    </DriverSummaryContent>
  </DriverSummaryContainer>
);

export { DriverSummary };
