import {
  Box,
  Stack,
  Badge,
  Text,
  Heading,
  Divider,
  Hidden,
} from 'braid-design-system';
import React from 'react';
import moment from 'moment';
import { NoDataState } from '../../NoDataState/NoDataState';
import { Tiles } from '../../Tiles/Tiles';

export type Shareprice = {
  symbol: string;
  exchange: string;
  currency: string;
  date: string;
  time: string;
  open: number;
  high: number;
  low: number;
  lastprice: number;
  prevClose: number;
  oneYearHigh: number;
  oneYearLow: number;
  movementPercent: number;
  movement: number;
  volume: number;
};

type Props = {
  sharePrice: Shareprice;
};

export const SharePrice = ({ sharePrice }: Props) => {
  if (sharePrice) {
    return <DisplaySharePriceContent sharePrice={sharePrice} />;
  }
  return <NoDataState />;
};

const DisplaySharePriceContent = ({ sharePrice }: Props) => (
  <Box>
    <Box paddingY="medium">
      <Box
        borderRadius="large"
        boxShadow="borderNeutralLight"
        overflow="hidden"
      >
        <Tiles columns={{ mobile: 1, tablet: 2 }} space="none">
          <CurrentShares sharePrice={sharePrice} />
          <Box background="neutralLight" height="full" paddingY="small">
            <Column heading="Exchange" value={sharePrice?.exchange} />
            <Column heading="Symbol" value={sharePrice?.symbol} />
            <Column heading="Currency" value={sharePrice?.currency} />
          </Box>
        </Tiles>
      </Box>
    </Box>
    <Box paddingY="medium">
      <Box
        borderRadius="large"
        boxShadow="borderNeutralLight"
        overflow="hidden"
      >
        <Tiles columns={1} space="none">
          <DisplayRowData
            heading1="Open price"
            value1={`$${sharePrice?.open.toFixed(2)}`}
            value2={sharePrice?.volume.toLocaleString('en-us')}
            heading2="Volume"
          />
          <Divider />
          <DisplayRowData
            heading1="Today's high"
            value1={`$${sharePrice?.high.toFixed(2)}`}
            value2={`$${sharePrice?.low.toFixed(2)}`}
            heading2="Today's low"
          />
        </Tiles>
      </Box>
    </Box>
  </Box>
);

type CurrentSharesProps = {
  sharePrice: Shareprice;
};

const CurrentShares = ({ sharePrice }: CurrentSharesProps) => {
  const date = moment(sharePrice?.date, 'YYYYMMDD');
  const formattedDate = date.format('DD MMM YYYY');
  const changePercent = sharePrice?.movementPercent || 0;
  return (
    <Box padding="large">
      <Stack space="medium">
        <Text size="small" tone="secondary">
          {formattedDate}
        </Text>
        <Heading level="1">A${sharePrice?.lastprice.toFixed(2)}</Heading>
        <Hidden above="mobile">
          {changePercent > 0 ? (
            <Badge tone="positive">Change {changePercent.toFixed(2)}%</Badge>
          ) : (
            <Badge tone="critical">Change {changePercent.toFixed(2)}%</Badge>
          )}
        </Hidden>
      </Stack>
      <Hidden below="tablet">
        <Box paddingTop="xxlarge">
          {changePercent > 0 ? (
            <Badge tone="positive">Change {changePercent.toFixed(2)}%</Badge>
          ) : (
            <Badge tone="critical">Change {changePercent.toFixed(2)}%</Badge>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};

type fieldHeadingProps = {
  fieldHeading: string;
};

type fieldValueProps = {
  fieldValue: string | undefined;
};

type DisplayRowDataProps = {
  heading1: string;
  value1: string | undefined;
  heading2: string;
  value2: string | undefined;
};

const DisplayRowData = ({
  heading1,
  value1,
  heading2,
  value2,
}: DisplayRowDataProps) => (
  <>
    <Hidden above="mobile">
      <Column heading={heading1} value={value1} />
      <Divider />
      <Column heading={heading2} value={value2} />
    </Hidden>
    <Hidden below="tablet">
      <Tiles columns={2} space="none">
        <Column heading={heading1} value={value1} />
        <Column heading={heading2} value={value2} />
      </Tiles>
    </Hidden>
  </>
);

type ColumnProps = {
  heading: string;
  value: string | undefined;
};
const Column = ({ heading, value }: ColumnProps) => (
  <Box paddingX="gutter" paddingY="medium">
    <Tiles columns={{ mobile: 2 }} space="medium">
      <FieldHeading fieldHeading={heading} />
      <FieldValue fieldValue={value} />
    </Tiles>
  </Box>
);

const FieldHeading = ({ fieldHeading }: fieldHeadingProps) => (
  <Text weight="strong" size="small" tone="neutral">
    {fieldHeading}
  </Text>
);

const FieldValue = ({ fieldValue }: fieldValueProps) => (
  <Text size="small" tone="neutral" align="right">
    {fieldValue}
  </Text>
);
