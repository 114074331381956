import type {
  GetPagesQuery,
  GetPagesWithEntitiesQuery,
} from '@seek/cmsu-cms-connect';
import React from 'react';
import { useLoaderData, useParams } from 'react-router';
import { useConfig } from 'src/client/shared/hooks';
import { PageComponentWrapper } from '../../components/PageComponent/PageComponentWrapper';
import { usePageTracking } from 'src/client/shared/hooks/usePageTracking';
import { deHyphen } from 'src/client/shared/utils/tealiumTracker/tealiumAdapterHelper';
import { useSearchParams } from 'react-router-dom';
import {
  enabledSubscriptionForm,
  getSectionsData,
} from 'src/client/shared/utils/helper';

export const Page = () => {
  const { fullURL, melwaysLocale, section } = useConfig();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const page = useLoaderData() as GetPagesQuery['pages'][0];
  const previousSiteSection = deHyphen(page.slug);
  const sectionDetails =
    page.relatedPageSection as GetPagesWithEntitiesQuery['pages'][0]['relatedPageSection'];
  const sectionData = getSectionsData(sectionDetails);
  const drivers = [];
  if (searchParams) {
    const primaryDriver = searchParams.get('primaryDriver');
    const secondaryDriver = searchParams.get('secondaryDriver');
    if (primaryDriver) {
      drivers.push({
        driver: primaryDriver.toLowerCase(),
        selection: searchParams
          .get('primaryDriverOptions')
          ?.replace('_', ',')
          .toLowerCase(),
      });
    }
    if (secondaryDriver) {
      drivers.push({
        driver: secondaryDriver.toLowerCase(),
        selection: searchParams
          .get('secondaryDriverOptions')
          ?.replace('_', ',')
          .toLowerCase(),
      });
    }
  }
  usePageTracking(
    page.title.toLocaleLowerCase(),
    slug,
    previousSiteSection,
    sectionData,
    drivers,
  );

  const displaySubscriptionForm = enabledSubscriptionForm(
    section,
    page.hideEmbeddedFormInSection?.sectionName,
  );

  return (
    <PageComponentWrapper
      page={page}
      fullURL={fullURL}
      melwaysLocale={melwaysLocale}
      enabledSubscriptionForm={displaySubscriptionForm}
    />
  );
};
