import type { Country, Locale } from '@seek/melways-sites';
import format from 'format-number';

const displayNames = new Intl.DisplayNames(['en'], { type: 'region' });

const customCountryNames: Record<string, string> = {
  ph: 'the Philippines',
  hk: 'Hong Kong',
};

const normaliseCountryCode = (country: string) => country.toLocaleLowerCase();

export const currencySymbolByCountry = (country: Country) => {
  const normalisedCountry = normaliseCountryCode(country);
  switch (normalisedCountry) {
    case 'my':
      return 'RM';
    case 'id':
      return 'RP';
    case 'th':
      return '฿';
    case 'ph':
      return '₱';
    default:
      return '$';
  }
};

export const getCountryName = (country: Country): string => {
  try {
    const normalisedCountry = normaliseCountryCode(country);
    if (customCountryNames[normalisedCountry]) {
      return customCountryNames[normalisedCountry];
    }
    return displayNames.of(normalisedCountry.toUpperCase()) || country;
  } catch (e) {
    return country;
  }
};

export const formatCurrency = (amount: number, country: Country) =>
  format({ prefix: currencySymbolByCountry(country) })(amount);

export const formatNumber = (
  value: number | string,
  locale: Locale | undefined,
) => {
  const resolvedLocale = locale && locale !== 'en-ID' ? locale : 'en-AU';
  return new Intl.NumberFormat(resolvedLocale).format(Number(value));
};

// Insert an item between each item in an array
// eg., insertBetweenItems([1, 2, 3], {x}) => [1, {x}, 2, {x}, 3]
export const insertBetweenArrayItems = (arr: any[], x: any) =>
  arr.flatMap((e) => [e, x]).slice(0, -1);
