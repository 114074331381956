import { createForm } from '@seek/forms-ui';
import {
  ALL_DRIVER,
  datalabHelper,
  type DatalabSurvey,
} from '../../datalabHelper';
import { datalabMapper } from '../../datalabMapper';
import { DatalabProvider } from '../../DatalabProvider';
import type { DriverList } from '../QueryFilter';
import type { FormValues } from '../QueryFilterForm';
import { useCMSUContext } from '../../../../hooks/useCMSUContext';
import { BannerQueryFilter } from './BannerQueryFilter';
import type { Locale } from '@seek/melways-sites';

const { FormProvider, useField: useQueryFilterBannerField } =
  createForm<FormValues>();
export { useQueryFilterBannerField };

export const QueryFilterBannerForm = ({
  datalabSurvey,
}: {
  datalabSurvey?: DatalabSurvey;
}) => {
  const { language } = useCMSUContext();

  if (datalabSurvey === undefined) return null;
  const { queryFilters, translations } = datalabSurvey;
  const driverList: DriverList = datalabHelper.getDriverListFrom(queryFilters);
  const initialValues: FormValues = {
    primaryDriverSelect: ALL_DRIVER,
    primaryDriverOptionsSelect: [],
    secondaryDriverSelect: '',
    secondaryDriverOptionsSelect: [],
  };
  const datalabTranslations =
    datalabMapper.mapToDataTranslationsMap(translations);
  return (
    <DatalabProvider
      fullURL={''}
      fullURLWithQueryParams={''}
      // it shouldnt be used like this, but for the scope of this component where the locale
      // is only used for translations, it should be fine
      siteLocale={language as Locale}
      datalabTranslations={datalabTranslations}
    >
      <FormProvider initialValues={initialValues}>
        {({ handleSubmit, setFieldValue }) => {
          const clearField = (fieldName: keyof FormValues) => {
            let emptyValue;
            if (Array.isArray(initialValues[fieldName])) {
              emptyValue = [];
            } else {
              emptyValue =
                fieldName === 'primaryDriverSelect' ? ALL_DRIVER : '';
            }
            setFieldValue(fieldName, emptyValue);
          };

          return (
            <BannerQueryFilter
              driverList={driverList}
              handleSubmit={handleSubmit}
              clearField={clearField}
            />
          );
        }}
      </FormProvider>
    </DatalabProvider>
  );
};
