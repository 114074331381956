import {
  type CustomComponentFragment,
  type FormFragment,
  InvestorOptions,
  type TopicListFragment,
  type WebFormFragment,
} from '@seek/cmsu-cms-connect';
import {
  type BlockItemRenderers,
  blockRenderers,
  BraidComponent,
  BugcrowdForm,
  CForm,
  CustomComponentRenderer,
  PracticeInterviewBuilder,
  WebForm,
} from '@seek/cmsu-components';

import { Dividend } from '@seek/cmsu-components/src/modules/InvestorInformation/Dividend/Dividend';
import {
  type Shareprice,
  SharePrice,
} from '@seek/cmsu-components/src/modules/InvestorInformation/SharePrice/SharePrice';
import { TopicList } from '@seek/cmsu-components/src/modules/TopicList/TopicList';
import { AsxAnnouncementsLoader } from '../client/components/modules/AsxAnnouncement/AsxAnnouncementsLoader';
import { SearchBoxWrapper } from '../client/components/SearchBoxWrapper/SearchBoxWrapper';
import { useConfig } from 'src/client/shared/hooks';
import { LatestArticlesByCategoryLoader } from '../client/components/modules/LatestArticlesByCategory/LatestArticlesByCategoryLoader';
import { WPT_SALESFORCE_API } from '../config';
import { getPrivacyLink } from './form';
import type { AsxAnnouncement } from '@seek/cmsu-components/src/modules/InvestorInformation/AsxAnnouncements/AsxAnnouncements';
import { Datalab } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/Datalab';
import type { DatalabComponent } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabHelper';

export type DividendInvestorInformation = CustomComponentFragment['data'] & {
  externalResources: Dividend[];
};

export type SharePriceInvestorInformation = CustomComponentFragment['data'] & {
  externalResources: Shareprice;
};

export type AsxAnnouncementsInvestorInformation =
  CustomComponentFragment['data'] & {
    externalResources: AsxAnnouncement;
  };

type WebFormProps = Pick<
  WebFormFragment,
  'formType' | 'document' | 'confirmationMessage' | 'webFormColumns'
>;

export const pageRenderers: BlockItemRenderers = {
  ...blockRenderers.hirer,
  CCustomComponent: ({ data }, additionalData) => {
    const { country, melwaysLocale, sourceName, privacyUrl, recaptchaKey } =
      useConfig();
    return data ? (
      <CustomComponentRenderer
        data={data}
        renderers={{
          ...blockRenderers.customComponentRenderers,
          SearchBox: () => <SearchBoxWrapper />,
          WebForm: ({
            document,
            formType,
            confirmationMessage,
            webFormColumns,
          }: WebFormProps) => (
            <WebForm
              country={country}
              locale={melwaysLocale}
              privacyUrl={privacyUrl}
              sourceName={sourceName}
              recaptchaKey={recaptchaKey}
              columns={webFormColumns}
              formType={formType}
              confirmationMessage={confirmationMessage?.raw}
              document={document?.url}
              wptSalesForceApi={WPT_SALESFORCE_API}
              privacyLink={getPrivacyLink(melwaysLocale)}
            />
          ),
          CForm: ({ form }) => (
            <CForm
              locale={melwaysLocale}
              sourceName={sourceName}
              privacyUrl={privacyUrl}
              recaptchaKey={recaptchaKey}
              wptSalesForceApi={WPT_SALESFORCE_API}
              country={country}
              form={form as FormFragment}
            />
          ),
          BraidComponent: ({ braidCode }) => (
            <BraidComponent braidCode={braidCode} />
          ),
          BugcrowdForm: () => <BugcrowdForm />,
          InterviewBuilder: () => <PracticeInterviewBuilder />,
          InvestorInformation: (investorInformation) => {
            switch (investorInformation.options) {
              case InvestorOptions.Dividends:
                return (
                  <Dividend
                    dividends={
                      (investorInformation as DividendInvestorInformation)
                        .externalResources
                    }
                  />
                );
              case InvestorOptions.SharePrice:
                return (
                  <SharePrice
                    sharePrice={
                      (investorInformation as SharePriceInvestorInformation)
                        .externalResources
                    }
                  />
                );
              case InvestorOptions.LatestAsxAnnouncements:
                return (
                  <AsxAnnouncementsLoader
                    asxAnnouncements={
                      (
                        investorInformation as AsxAnnouncementsInvestorInformation
                      ).externalResources
                    }
                    fullUrl={additionalData.fullURL}
                    limit={5}
                    showFilter={false}
                  />
                );
              case InvestorOptions.AsxAnnouncements:
                return (
                  <AsxAnnouncementsLoader
                    asxAnnouncements={
                      (
                        investorInformation as AsxAnnouncementsInvestorInformation
                      ).externalResources
                    }
                    fullUrl={additionalData.fullURL}
                    showFilter={true}
                  />
                );
              default:
                return <></>;
            }
          },
        }}
        additionalData={additionalData}
      />
    ) : null;
  },
  LatestArticlesByCategory: ({
    headingText,
    descriptionText,
    category,
    stage,
  }) => (
    <LatestArticlesByCategoryLoader
      heading={headingText}
      description={descriptionText}
      categorySlug={category?.slug}
      stage={stage}
    />
  ),
  TopicList: (topicList: TopicListFragment) => {
    const { language, brand } = useConfig();
    return (
      <TopicList topicList={topicList} brand={brand} language={language} />
    );
  },
  Datalab: (data) => {
    const { tipsCardGroup, aboutResearchLink } = data;
    const { datalabSurvey } = data as DatalabComponent;
    const { fullURL, country, melwaysLocale, site, language } = useConfig();

    return (
      <Datalab
        datalabSurvey={datalabSurvey}
        tipsCards={tipsCardGroup?.items}
        fullURL={fullURL}
        country={country}
        locale={melwaysLocale}
        site={site}
        language={language}
        aboutResearchLink={aboutResearchLink}
      />
    );
  },
};
