import {
  HiddenBelow,
  type BoxFragment,
  type ImageBlockFragment,
  type ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Columns, Column, Stack, Actions } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { TextLink } from '../ActionGroup/TextLink';
import { DialogButton } from '../ActionGroup/DialogButton';
import { toUrl } from '../../utils/toUrl';
import { getBoxBackgroundProps } from '../PageComponent/helper';
import { getElementAction } from '../../utils/getElementAction';
import { useCMSUContext } from '../../hooks/useCMSUContext';

type Props = Pick<
  ImageBlockFragment,
  | 'heading'
  | 'image'
  | 'paragraph'
  | 'cButtonLink'
  | 'cTextLink'
  | 'dialogButton'
> &
  Pick<
    ImageBlockGroupFragment,
    'imageAlignment' | 'blockBackground' | 'stackBelow'
  > & {
    inlineTextJustify?: BoxFragment['justifyContent'];
  };

const getCollapseProp = (stackBelow: ImageBlockGroupFragment['stackBelow']) => {
  switch (stackBelow) {
    case HiddenBelow.Tablet:
      return 'tablet';
    case HiddenBelow.Desktop:
      return 'desktop';
    default:
      return undefined;
  }
};

const getAlignYProp = (inlineTextJustify: BoxFragment['justifyContent']) => {
  switch (inlineTextJustify) {
    case 'flexStart':
      return 'top';
    case 'flexEnd':
      return 'bottom';
    case 'center':
      return 'center';
    default:
      return 'top';
  }
};

const getImagePaddingGap = (imageWidth: number) => {
  if (imageWidth > 100) {
    return 'medium';
  }
  if (imageWidth > 70) {
    return 'small';
  }
  if (imageWidth > 40) {
    return 'xsmall';
  }
  if (imageWidth > 25) {
    return 'xsmall';
  }

  return 'none';
};

export const InlineImageBlock = ({
  imageAlignment,
  image,
  heading,
  paragraph,
  cButtonLink,
  cTextLink,
  dialogButton,
  stackBelow,
  inlineTextJustify,
  blockBackground,
}: Props) => {
  const textLinkTrackingAttributes = {
    elementText: cTextLink?.text || '',
    elementLink: toUrl(cTextLink?.link?.to),
    elementDesign: 'link',
  };
  const { language, utmParameters } = useCMSUContext();
  const hasCta = cButtonLink || dialogButton || cTextLink;
  return (
    <Box
      padding={blockBackground ? 'gutter' : 'none'}
      borderRadius={blockBackground ? 'large' : 'none'}
      height="full"
      {...getBoxBackgroundProps(blockBackground)}
    >
      <Columns
        space="medium"
        collapseBelow={getCollapseProp(stackBelow)}
        alignY={getAlignYProp(imageAlignment)}
      >
        {image ? (
          <Column width="content">
            <Box
              display="flex"
              alignItems={imageAlignment || 'flexStart'}
              paddingRight={getImagePaddingGap(image.width!)}
            >
              <Asset
                assetType={AssetType.DEFAULT_IMAGE}
                {...image}
                style={{ maxWidth: 'fit-content' }}
              />
            </Box>
          </Column>
        ) : null}
        <Column>
          <Box
            width="full"
            display="flex"
            flexDirection="column"
            justifyContent={inlineTextJustify ?? 'center'}
          >
            <Stack space="large">
              <Stack space="small">
                {heading && <Heading {...heading} />}
                {paragraph && (
                  <Paragraph
                    align={paragraph.align}
                    tone={paragraph.tone}
                    size={paragraph.size}
                    content={paragraph.Paragraph_text?.raw}
                  />
                )}
              </Stack>
              {hasCta ? (
                <Actions>
                  {cButtonLink && (
                    <Box display="inlineBlock">
                      <ButtonLink
                        link={toUrl(
                          cButtonLink.link?.to,
                          language,
                          utmParameters,
                        )}
                        text={cButtonLink.text}
                        tone={cButtonLink.tone || 'neutral'}
                        variant={cButtonLink.variant || 'solid'}
                        size={cButtonLink.size || 'standard'}
                        icon={cButtonLink.icon}
                        iconPosition={cButtonLink.iconPosition}
                        openLinkInNewTab={
                          cButtonLink.link?.openLinkInNewTab || false
                        }
                        uniqueTrackingId="button-link"
                        trackingAttributes={{
                          elementText: cButtonLink.text,
                          elementAction: getElementAction(cButtonLink.link?.to),
                          elementLink: toUrl(
                            cButtonLink.link?.to,
                            language,
                            utmParameters,
                          ),
                        }}
                      />
                    </Box>
                  )}
                  {dialogButton && (
                    <Box display="inlineBlock">
                      <DialogButton {...dialogButton} />
                    </Box>
                  )}
                  {cTextLink && (
                    <Box display="inlineBlock">
                      <TextLink
                        uniqueTrackingId="text-link"
                        trackingAttributes={textLinkTrackingAttributes}
                        {...cTextLink}
                      />
                    </Box>
                  )}
                </Actions>
              ) : null}
            </Stack>
          </Box>
        </Column>
      </Columns>
    </Box>
  );
};
