import type {
  ImageBlockFragment,
  ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Stack } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { getBoxBackgroundProps } from '../PageComponent/helper';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { TextLink } from '../ActionGroup/TextLink';
import { DialogButton } from '../ActionGroup/DialogButton';
import { getElementAction } from '../../utils/getElementAction';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { toUrl } from '../../utils/toUrl';

type Props = Pick<
  ImageBlockFragment,
  | 'heading'
  | 'image'
  | 'paragraph'
  | 'cButtonLink'
  | 'cTextLink'
  | 'dialogButton'
> &
  Pick<ImageBlockGroupFragment, 'imageAlignment' | 'blockBackground'>;

export const RegularImageBlock = ({
  image,
  heading,
  paragraph,
  blockBackground,
  cButtonLink,
  cTextLink,
  dialogButton,
}: Props) => {
  const { language, utmParameters } = useCMSUContext();
  return (
    <Box
      padding={'gutter'}
      borderRadius={blockBackground ? 'large' : 'none'}
      height="full"
      {...getBoxBackgroundProps(blockBackground)}
    >
      <Stack space="medium" align="center">
        {image && (
          <Box borderRadius="large" overflow="hidden">
            <Asset {...image} assetType={AssetType.DEFAULT_IMAGE} />
          </Box>
        )}
        {heading && <Heading {...heading} />}
        {paragraph && (
          <Paragraph
            content={paragraph.Paragraph_text?.raw}
            align={paragraph.align}
            tone={paragraph.tone}
            size={paragraph.size}
          />
        )}
        {cButtonLink && (
          <Box display="inlineBlock">
            <ButtonLink
              link={toUrl(cButtonLink.link?.to, language, utmParameters)}
              text={cButtonLink.text}
              tone={cButtonLink.tone || 'neutral'}
              variant={cButtonLink.variant || 'solid'}
              size={cButtonLink.size || 'standard'}
              icon={cButtonLink.icon}
              iconPosition={cButtonLink.iconPosition}
              openLinkInNewTab={cButtonLink.link?.openLinkInNewTab || false}
              uniqueTrackingId="button-link"
              trackingAttributes={{
                elementText: cButtonLink.text,
                elementAction: getElementAction(cButtonLink.link?.to),
                elementLink: toUrl(
                  cButtonLink.link?.to,
                  language,
                  utmParameters,
                ),
              }}
            />
          </Box>
        )}
        {dialogButton && (
          <Box display="inlineBlock">
            <DialogButton {...dialogButton} />
          </Box>
        )}
        {cTextLink && (
          <Box display="inlineBlock">
            <TextLink {...cTextLink} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
