import type { Country } from '@seek/melways-sites';
import React from 'react';

import type { BannerRenderers } from '../BannerRenderer';
import { PrimaryBanner } from '../../../modules/Banner/PrimaryBanner/PrimaryBanner';
import { HacqShopfrontBanner } from '../../../modules/Banner/HacqShopfrontBanner/HacqShopfrontBanner';
import { AwardsBanner } from '../../../modules/Banner/AwardsBanner/AwardsBanner';

export const universal: BannerRenderers = {
  CBannerPrimary: (banner, country) => (
    <PrimaryBanner
      {...banner}
      country={country as Country}
      image={banner.BannerPrimary_image}
    />
  ),
  CBannerShopfront: (banner) => (
    <HacqShopfrontBanner
      {...banner}
      footnotes={banner.BannerShopfront_footnotes}
    />
  ),
  CBannerAward: (banner, _country) => <AwardsBanner {...banner} />,
};
