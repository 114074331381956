import { Dropdown, Hidden, Inline, Stack, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from '../../.vocab';

type YearFilterProps = {
  availableYears: number[];
  selectedYear: number;
  onYearChange: (year: number) => void;
};

export const YearFilter: React.FC<YearFilterProps> = ({
  availableYears,
  selectedYear,
  onYearChange,
}) => {
  const { t } = useTranslations(translations);

  const label = <Text>{t('Filter by year')}</Text>;
  const dropDownFilter = (
    <Dropdown
      id="YEAR_FILTER"
      label=""
      value={selectedYear}
      onChange={(event) => onYearChange(Number(event.currentTarget.value))}
    >
      <>
        <option value="-1">All</option>
        {availableYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </>
    </Dropdown>
  );

  return (
    <>
      <Hidden above="mobile">
        <Stack space="small">
          {label}
          {dropDownFilter}
        </Stack>
      </Hidden>
      <Hidden below="tablet">
        <Inline space="small" alignY="center">
          {label}
          {dropDownFilter}
        </Inline>
      </Hidden>
    </>
  );
};
