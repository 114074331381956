import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_a4wh3t7jwjopzbsr5zdon56pg4/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61SwXKCMBC9+xU70+mMHuKggha89NT/iMmK20LChGCxnf57hxjaQKv2UC4Q9uW9t/t2LsrNYlMv4H0CYLG1jBeUqwwEKotmOwE4IOUHm8Eiiu67c8lb9krSHoa/elgcRVW7nXxM5mfq5f9Rr0bUqyvUOy5ecqMbJZnBCrnNQGn/OarX9IYZCK0sJ9XVbncH8MZISWwzSNPAUewcBdxCF9pkcPfkHgyxyQXskZspY3TaHcVh2cycWW0kGma4pKYeIsQsoNw4Skl1VfBTBvsCndfnpra0P7GuRVT2WgIVl5JUzqyuhjqrWVjeaWt1+RPx5eThdyfdm0kyKCzpLi9dNKWbuQuQkcWyDv39MhMb6qTnIZ7nU+C+a6VqodYFyR+3vH5u9GsGizDpwVpxx3lBuVsHk5Ma5eQ8PZYoiUMtDKICriRMS1L9Rm/iqGpnjtsLRe4A0IH6INb9gn1842KP89MfSifO1NUdkR7ijazPUQcCyV8Exrdv9Zumy1G/fR8ekFz0cRGw9oCvxVJaobfzCfvsZg7LBAAA\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_a4wh3t7jwjopzbsr5zdon56pg4/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = 'cm717s6';
export var BannerTextColumnBoxStyle = 'cm717s4';
export var ShopfrontBannerTextColumnBoxStyle = 'cm717s5';
export var VerticalDivider = 'cm717s8';
export var VerticalDividerText = 'cm717sa';
export var VerticalDividerWrapper = 'cm717s7';
export var VerticalLine = 'cm717s9';
export var bannerContainer = 'cm717s0';
export var headerImageStyle = 'cm717s2';
export var headerImageStyleEnlarged = 'cm717s1';
export var tertiaryImageStyle = 'cm717s3';