import {
  ApacCandidateHeader,
  ApacCandidateFooter,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';
import { useMelwaysLink } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';

import { useAuth, useConfig } from 'src/client/shared/hooks';
import { linkRenderer } from '../modules/renderers/linkRenderer';
import type { Section } from '@seek/cmsu-cms-connect';
import { CustomPageHeader } from '@seek/cmsu-components';
import { getHeaderConfig } from 'src/client/shared/utils/helper';
import { RootSiteFooter } from '../Footers/RootSiteFooter';
import { Page } from 'braid-design-system';

interface PageProps {
  children: React.ReactNode;
  section?: Partial<Section>;
  layout?: 'full' | 'basic' | 'none';
}

export const CandidateLayout = ({ children, section }: PageProps) => {
  const { country, language, hygraphLocale, route, routeArray, site } =
    useConfig();
  const { authenticationStatus, caAccountContext } = useAuth();
  const { sectionName, headerOptions } = section || {};

  const urlResolver = useMelwaysLink();
  const path = route.split(`/${country}`).pop() || route;

  const getLanguageSwitcher = (c: Country) => {
    if (c !== 'id' && c !== 'th') {
      return undefined;
    }

    return {
      en: {
        url: urlResolver({ language: 'en', path }),
      },

      [country]: {
        url: urlResolver({ language: c, path }),
      },
    };
  };

  const { type, layout, logo, links, divider, linksAlignment } =
    getHeaderConfig(headerOptions);

  return (
    <ApacCandidateHeaderFooterLinkProvider value={linkRenderer}>
      {/* Root sites are not compatible with the candiate-header-footer */}
      <Page
        footer={
          site !== 'candidate-seek-root' ? (
            <ApacCandidateFooter authenticationStatus={authenticationStatus} />
          ) : (
            <RootSiteFooter />
          )
        }
      >
        {/* Root sites are not compatible with the candiate-header-footer
      due to melways-sites functions needing more information than exists
      for the root sites */}
        {type === 'SeekHeader' &&
        layout !== 'none' &&
        site !== 'candidate-seek-root' ? (
          <ApacCandidateHeader
            activePrimaryTab={
              sectionName === 'profile' ? sectionName : undefined
            }
            authenticationStatus={authenticationStatus}
            leanHeader={layout === 'basic' && true}
            languageSwitcher={getLanguageSwitcher(country)}
            userProfile={caAccountContext}
          />
        ) : (
          <CustomPageHeader
            divider={divider}
            logo={logo}
            links={links}
            language={language}
            route={routeArray}
            country={country}
            locale={hygraphLocale}
            linksAlignment={linksAlignment}
          />
        )}
        {children}
      </Page>
    </ApacCandidateHeaderFooterLinkProvider>
  );
};
