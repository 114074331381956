import type { Dividend } from '@seek/cmsu-components/src/modules/InvestorInformation/Dividend/Dividend';
import type { Shareprice } from '@seek/cmsu-components/src/modules/InvestorInformation/SharePrice/SharePrice';
import type {
  AsxAnnouncement,
  AsxFilterValues,
} from '@seek/cmsu-components/src/modules/InvestorInformation/AsxAnnouncements/AsxAnnouncements';

// special dividends based on this ticket
// https://myseek.atlassian.net/browse/WP-3997
interface asxFilters {
  year: number;
}

const SPECIAL_DIVIDEND_DATES = ['20210524'];

const loadDividends = async (): Promise<Dividend[] | undefined> => {
  const response = await fetch(
    'https://wcsecure.weblink.com.au/clients/seek/dividendjson.aspx',
  );

  if (response.status === 200)
    return ((await response.json()).data as Dividend[]).map((e) => {
      if (SPECIAL_DIVIDEND_DATES.includes(e.PaymentDate)) {
        e.DividendType = 'D';
      }
      return e;
    });

  return undefined;
};

const loadSharePrice = async (): Promise<Shareprice | undefined> => {
  const response = await fetch(
    'https://wcsecure.weblink.com.au/clients/seek/quotejson.aspx',
  );

  if (response.status === 200) return (await response.json())[0] as Shareprice;
  return undefined;
};

const latestAsxAnnouncementsUrl =
  'https://wcsecure.weblink.com.au/clients/seek/headlinejson.aspx';

type LoadLatestAsxAnnouncementsProps = {
  pageNumber?: number | string;
  request: Request;
};
const loadLatestAsxAnnouncements = async ({
  pageNumber,
  request,
}: LoadLatestAsxAnnouncementsProps): Promise<AsxAnnouncement | undefined> => {
  const url = new URL(latestAsxAnnouncementsUrl);
  const filter = getAsxFilterFrom(request);
  const responseWithoutFilter = await fetch(url.toString());
  const asxAnnoumcements = (await responseWithoutFilter.json())
    .WebLinkHeadlineData as AsxAnnouncement;
  const filterValues: AsxFilterValues =
    getAsxFilterValuesFrom(asxAnnoumcements);
  // IMPORTANT: page number is 1-indexed
  if (pageNumber && Number(pageNumber) > 0) {
    url.searchParams.set('pageNumber', pageNumber.toString());
  }
  url.searchParams.set('year', filter.year.toString()); // Ensure the backend receives the year filter
  const response = await fetch(url.toString());
  if (response.status === 200)
    return {
      ...((await response.json()).WebLinkHeadlineData as AsxAnnouncement),
      filterValues,
    } as AsxAnnouncement;
  return undefined;
};

export const getAsxFilterValuesFrom = (
  asxAnnouncement: AsxAnnouncement,
): AsxFilterValues => {
  const { oldestYear, latestYear } = asxAnnouncement;
  const years =
    oldestYear && latestYear
      ? Array.from(
          { length: latestYear - oldestYear + 1 },
          (_, i) => oldestYear + i,
        )
      : [];
  const sortedDescYears = years.sort((a, b) => b - a);
  return {
    years: sortedDescYears,
  };
};

export const getAsxFilterFrom = (request: Request): asxFilters => {
  const searchParams = new URLSearchParams(
    request.url.substring(request.url.indexOf('?')),
  );
  const year = searchParams.get('year');
  return {
    year: year && !isNaN(Number(year)) ? Number(year) : -1, // explicityly check for empty strings and non-numeric values
  };
};

export const weblinkLoader = {
  loadDividends,
  loadSharePrice,
  loadLatestAsxAnnouncements,
};

// include this because it can be used for pagination
export const weblinkUrl = {
  latestAsxAnnouncement: latestAsxAnnouncementsUrl,
};
