import { AsxAnnouncements } from '@seek/cmsu-components';
import type { AsxAnnouncement } from '@seek/cmsu-components/src/modules/InvestorInformation/AsxAnnouncements/AsxAnnouncements';
import type { AdditionalData } from '@seek/cmsu-components/src/renderer/AdditionalData';
import { useConfig } from 'src/client/shared/hooks';

type Props = {
  fullUrl: AdditionalData['fullURL'];
  asxAnnouncements: AsxAnnouncement;
  limit?: number;
  showFilter: boolean;
};

const getPathWithoutPageNumber = (fullUrl: string) => {
  const lastPart = fullUrl.split('/').pop();
  if (lastPart && !isNaN(Number(lastPart))) {
    return fullUrl.split('/').slice(0, -1).join('/');
  }
  return fullUrl;
};

export const AsxAnnouncementsLoader = ({
  fullUrl,
  asxAnnouncements,
  limit,
  showFilter,
}: Props) => {
  const { language } = useConfig();

  const pathWithoutPageNumber = getPathWithoutPageNumber(fullUrl);
  return (
    <AsxAnnouncements
      asxAnnouncements={asxAnnouncements}
      language={language}
      limit={limit}
      pathname={pathWithoutPageNumber}
      showFilter={showFilter}
    />
  );
};
