import {
  Box,
  Dropdown,
  FieldLabel,
  FieldMessage,
  IconChevron,
  IconClear,
  Stack,
  Text,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { memo, useEffect, useState } from 'react';
import Select, {
  components,
  type GroupBase,
  type MultiValue,
  type OptionsOrGroups,
  type StylesConfig,
} from 'react-select';

export interface SelectOption {
  label: string;
  value: string;
}
export interface MultipleSelectDropdownProps {
  options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>> | undefined;
  onChange: (newValue: MultiValue<SelectOption>) => void | undefined;
  canSelect: boolean;
  value: any;
  title?: string;
  description?: string;
  isDisabled?: boolean;
  message: string;
  isValid?: boolean;
  id: string;
  placeholder?: string;
  isDark?: boolean;
}

const MultipleSelectDropdownComponent = ({
  options,
  onChange = () => {},
  canSelect,
  value,
  title,
  description,
  isDisabled,
  message,
  isValid = true,
  id,
  placeholder,
  isDark = false, // Add control to change the text color/foreground color according to background type
}: MultipleSelectDropdownProps) => {
  const name = `${id}_dropdown`;
  const [isMounted, setIsMounted] = useState(false);
  // To prevent hydration warning errors
  // Reference: https://github.com/JedWatson/react-select/pull/5860
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    // Render a fallback or null while SSR is happening
    return (
      <Dropdown
        id={id}
        label={title}
        value={''}
        onChange={(_newValue) => {}}
        placeholder={placeholder}
        disabled
      >
        <option value="">{}</option>
      </Dropdown>
    );
  }

  return (
    <Box position="relative" background="customLight">
      <Stack space="small">
        <MultiSelectDropdownHeader
          title={title}
          name={name}
          isDisabled={isDisabled}
          description={description}
          isDark={isDark}
        />
        <Select<SelectOption, true>
          closeMenuOnSelect={true}
          isMulti
          aria-label={name}
          instanceId={name}
          inputId={name}
          // we need this one because we want to ensure the dropdown is always on top of sections,
          // because braid sections / containers have z-index
          menuPortalTarget={document.body}
          name={name}
          options={options}
          styles={getCustomStyles(value, isValid, isDisabled)}
          onChange={onChange}
          openMenuOnClick={canSelect}
          aria-invalid={!isValid}
          // Force it closed if you shouldn't be able to select it
          menuIsOpen={canSelect ? undefined : false}
          noOptionsMessage={() => (
            <Box paddingY="xsmall">
              <Text>No options</Text>
            </Box>
          )}
          value={value}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <Box data-cy={`multi-indicator-${name}`}>
                  <IconChevron
                    size="xsmall"
                    tone={isDisabled ? 'secondary' : 'neutral'}
                  />
                </Box>
              </components.DropdownIndicator>
            ),
            Placeholder: (props) => (
              <components.Placeholder {...props}>
                {placeholder}
              </components.Placeholder>
            ),
            MultiValueRemove: (props) => (
              <components.MultiValueRemove {...props}>
                <Box data-cy={`multi-remove-${name}`}>
                  <IconClear size="xsmall" tone={'secondary'} />
                </Box>
              </components.MultiValueRemove>
            ),
          }}
          isDisabled={isDisabled}
          isClearable={false}
        />
      </Stack>
      <ErrorFieldMessage message={message} isValid={isValid} isDark={isDark} />
    </Box>
  );
};
export const MultipleSelectDropdown = memo(MultipleSelectDropdownComponent);

const MultiSelectDropdownHeader = ({
  title,
  name,
  isDisabled,
  description,
  isDark,
}: {
  title?: string;
  name: string;
  isDisabled?: boolean;
  description?: string;
  isDark: boolean;
}) => (
  <Box background={isDark ? 'customDark' : 'customLight'}>
    {Boolean(title) && (
      <Box>
        <FieldLabel htmlFor={name} label={title} disabled={isDisabled} />
      </Box>
    )}
    {Boolean(description) && <Text>{description}</Text>}
  </Box>
);

const ErrorFieldMessage = ({
  message,
  isValid,
  isDark,
}: {
  message: string;
  isValid: boolean;
  isDark: boolean;
}) => (
  <Box position="absolute">
    {!isValid && (
      <Box
        marginTop="xxsmall"
        background={isDark ? 'customDark' : 'customLight'}
      >
        <FieldMessage id="driverMessage" tone="critical" message={message} />
      </Box>
    )}
  </Box>
);

const fontFamily = 'SeekSans, "SeekSans Fallback", Arial, Tahoma, sans-serif';

type CustomStylesContext = Pick<
  MultipleSelectDropdownProps,
  'isValid' | 'isDisabled'
>;
const customStyles = (
  ctx: CustomStylesContext,
): StylesConfig<SelectOption, true> => ({
  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (base) => ({
    ...base,
    fontFamily,
    fontSize: '16px',
    color: vars.foregroundColor.secondary,
  }),
  menuPortal: (base) => ({
    ...base,
    // lower than close buttton for modal.
    zIndex: 199,
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    position: 'relative',
    height: '48px',
    margin: 'auto',
    fontFamily,
    fontSize: '18px',
    backgroundColor: isDisabled
      ? vars.backgroundColor.neutralSoft
      : vars.backgroundColor.surface,
    borderRadius: vars.borderRadius.standard,
    border: getBorderStyle(ctx.isValid, isDisabled),
    boxShadow: 'unset',
    '&:hover': {
      border:
        ctx.isValid === false
          ? `2px solid ${vars.borderColor.critical}`
          : `2px solid ${vars.borderColor.formAccent}`,
    },
    '&:focus-within': {
      boxShadow: '0px 0px 0px 5px rgba(184,210,249)',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    position: 'relative',
    height: '95%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontFamily,
    fontSize: '16px',
    paddingRight: '25px',
    marginInline: 'auto',
    gap: vars.space.xxsmall,
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontFamily,
    color: vars.foregroundColor.neutral,
  }),
  multiValueRemove: (base) => ({
    ...base,
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: 'absolute',
    right: '-2px',
    height: '100%',
    width: vars.touchableSize,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: vars.foregroundColor.neutral,
  }),
  option: (base) => ({
    ...base,
    fontFamily,
    width: '98%',
    margin: '5px auto',
    borderRadius: vars.borderRadius.small,
  }),
});

const getBorderStyle = (isValid?: boolean, isDisabled?: boolean) => {
  if (isValid === false) return `2px solid ${vars.borderColor.critical}`;
  if (isDisabled) return `2px solid ${vars.borderColor.neutralLight}`;
  return `2px solid ${vars.borderColor.field}`;
};

const getCustomStyles = (
  options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>> | undefined,
  isValid?: boolean,
  isDisabled?: boolean,
) => {
  const ctx = { isValid, isDisabled };
  const styles = customStyles(ctx);

  styles.multiValue = (base) => ({
    ...base,
    ...multiValueOverrideStyles,
    ...(options && options.length > 1 && { maxWidth: '44%' }),
  });

  return styles;
};

const multiValueOverrideStyles = {
  height: '100%',
  marginBlock: 'auto',
  display: 'flex',
  alignItems: 'center',
  borderRadius: vars.borderRadius.xlarge,
  padding: '0 6px',
  fontFamily,
  backgroundColor: vars.backgroundColor.neutralSoft,
  '&:hover': {
    backgroundColor: vars.backgroundColor.neutralSoftHover,
  },
};
