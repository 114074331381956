import { useLocation } from 'react-router-dom';
import { Icon } from '@seek/cmsu-cms-connect';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language } from '@seek/melways-sites';
import { Box, Pagination, Stack } from 'braid-design-system';
import moment from 'moment';
import { useMemo } from 'react';
import { DocumentList } from '../../DocumentList/DocumentList';
import { NoDataState } from '../../NoDataState/NoDataState';
import { YearFilter } from '../../YearFilter/YearFilter';

// Types
type Headline = {
  date: string;
  time: string;
  articleID: string;
  pdfLink: string;
  HeadlineText: string;
  Symbol: string;
  pages: number;
};

export type AsxAnnouncement = {
  totalHeadlines: number;
  totalPages: number;
  category: string;
  pageNumber: number;
  oldestYear: number;
  latestYear: number;
  headlines: Headline[];
  filterValues: AsxFilterValues;
};

export type AsxFilterValues = {
  years: number[];
};

type AsxAnnouncementsContentProps = {
  headlines: Headline[];
};

type AsxAnnouncementsProps = {
  asxAnnouncements: AsxAnnouncement;
  pathname: string;
  language: Language;
  limit?: number;
  showFilter?: boolean;
};

type NavigationType = {
  page: number;
  year: number;
};

// Constants
const DATE_FORMAT = 'YYYYMMDD';
const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';

// Utility functions
const formatDate = (dateString: string): string =>
  moment(dateString, DATE_FORMAT).format(DISPLAY_DATE_FORMAT);

// Content component
const AsxAnnouncementsContent: React.FC<AsxAnnouncementsContentProps> = ({
  headlines,
}) => {
  const items = useMemo(
    () =>
      headlines.map((headline) => ({
        id: headline.articleID,
        title: headline.HeadlineText,
        date: formatDate(headline.date),
        externalUrl: {
          externalUrl: headline.pdfLink,
        },
      })),
    [headlines],
  );

  return (
    <DocumentList
      icon={Icon.Document}
      usePagination={false}
      documents={items}
    />
  );
};

const AsxFilter = ({
  availableYears,
  selectedYear,
  setSelectedYear,
}: {
  availableYears: number[];
  selectedYear: number;
  setSelectedYear: (year: number) => void;
}) => (
  <Box paddingBottom="medium">
    <YearFilter
      availableYears={availableYears}
      selectedYear={selectedYear}
      onYearChange={setSelectedYear}
    />
  </Box>
);

// AsxAnnouncements component: Displays ASX announcements with pagination
export const AsxAnnouncements: React.FC<AsxAnnouncementsProps> = ({
  asxAnnouncements,
  limit,
  language,
  pathname,
  showFilter = false,
}) => {
  const { search } = useLocation();
  const { totalPages, pageNumber, headlines, filterValues } = asxAnnouncements;
  const params = new URLSearchParams(search);
  const year = params.get('year');
  const selectedYear = year ? parseInt(year, 10) : -1; // Default to "ALL" (-1) if no year is provided
  const urlResolver = useMelwaysLink();

  // Determine if pagination is needed
  const hasMultiplePages = !limit && totalPages > 1;

  // Apply limit to headlines if specified
  const displayedHeadlines = useMemo(() => {
    const sortedHeadlines = [...headlines].sort(
      (a, b) => Number(b.date) - Number(a.date),
    );
    return limit ? sortedHeadlines.slice(0, limit) : sortedHeadlines;
  }, [headlines, limit]);

  const handleYearChange = (inputYear: number) => {
    navigateTo({
      year: inputYear,
      page: 1,
    });
  };

  const handlePaginationClick = (page: number) => {
    navigateTo({
      page,
      year: selectedYear,
    });
  };

  const navigateTo = (param: NavigationType) => {
    const nextPaginationUrl = urlResolver({
      language,
      path: `${pathname}/${param.page}?year=${
        param.year !== -1 ? param.year : ''
      }`,
    });
    window.location.href = nextPaginationUrl; // Redirects to new URL
  };

  if (!displayedHeadlines || displayedHeadlines.length === 0) {
    return <NoDataState />;
  }

  return (
    <Stack space={'large'}>
      {showFilter && (
        <AsxFilter
          availableYears={filterValues.years}
          selectedYear={selectedYear}
          setSelectedYear={handleYearChange}
        />
      )}

      <AsxAnnouncementsContent headlines={displayedHeadlines} />
      {hasMultiplePages && (
        <Box>
          <Pagination
            page={pageNumber}
            total={totalPages}
            label="pagination"
            linkProps={({ page: pageInPagination }) => ({
              href: '#',
              onClick: (e) => {
                e.preventDefault();
                handlePaginationClick(pageInPagination);
              },
            })}
          />
        </Box>
      )}
    </Stack>
  );
};
