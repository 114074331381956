import type { Article } from '@seek/cmsu-cms-connect';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import React from 'react';
import {
  articleActionByType,
  getArticleCardFooter,
  ITEM_DISPLAY_TYPE,
  resolveArticlePathFrom,
} from '../../helpers/articleHelper';
import { useMelwaysLink } from '@seek/melways-react';
import type { Brand, Language } from '@seek/melways-sites';
import { ArticleListItem } from '../ArticleListItem/ArticleListItem';
import { ArticleCard } from '../ArticleCard/ArticleCard';
import { Box } from 'braid-design-system';

type Props = {
  displayType: ITEM_DISPLAY_TYPE;
  article: Article;
  language: Language;
  brand: Brand;
  section?: string;
  hideCategory?: boolean;
  isLastItem?: boolean;
  index?: number;
};

export const ArticleItem = ({
  displayType,
  article,
  language,
  brand,
  section,
  hideCategory = false,
  isLastItem = false,
  index,
}: Props) => {
  const urlResolver = useMelwaysLink();
  const { t } = useTranslations(translations);

  const articleActionTranslated = t(articleActionByType(article.articleType));
  const articleItemFooterText = getArticleCardFooter({
    publishDate: article.publishDate,
    timeToRead: article.timeToRead,
    articleActionTranslated,
  });
  const path = resolveArticlePathFrom(article, section);
  const articleLink = urlResolver({
    language,
    path,
    brand,
  });

  if (displayType === ITEM_DISPLAY_TYPE.LIST_ITEM) {
    return (
      <Box data={{ testid: 'ArticleListItemTestId' }}>
        <ArticleListItem
          article={article}
          articleLink={articleLink}
          articleItemFooterText={articleItemFooterText}
          hideCategory={hideCategory}
          isLastItem={isLastItem}
        />
      </Box>
    );
  } else if (displayType === ITEM_DISPLAY_TYPE.CARD) {
    return (
      <Box height="full" data={{ testid: 'ArticleCardTestId' }}>
        <ArticleCard
          index={index}
          article={article}
          articleLink={articleLink}
          articleCardFooterText={articleItemFooterText}
          hideCategory={hideCategory}
        />
      </Box>
    );
  }
};
